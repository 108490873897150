import React from 'react'
import {
  Router,
  Route
} from 'react-router-dom'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { connect } from 'react-redux'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Callback from './routes/Callback'
import Home from './routes/Home'

import { createBrowserHistory } from 'history'
import Layout from './components/layout/Layout';
import './App.css'
import appConfig from './config/app-config.json'

const history = createBrowserHistory()

const theme = createTheme({
  palette: {
    primary: {
      main: '#F8F5F0',
      light: '#FBF9F7',
      dark: '#BAB7B4',
      contrastText: '#5A5754'
    },
    background: {
      paper: '#FBF9F7'
    }
  }
});

const httpLink = new HttpLink({ uri: `${appConfig.apiUri}/graphql` });

const App = (props) => {

  const authLink = new ApolloLink((operation, forward) => {
    if (props.session.isLoggedIn) {
      // Retrieve the authorization token from local storage.
      const token = `${props.session.credentials.idToken}`
      operation.setContext({
        headers: {
          authorization: token ? `${token}` : ''
        }
      });
    }

    // Call the next link in the middleware chain.
    return forward(operation);
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink), // Chain it with the HttpLink
    cache: new InMemoryCache(),
    fetchOptions: {
      mode: 'no-cors',
    },
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Layout>
            <Route exact path="/" component={Home} />
            <Route exact path="/callback" component={Callback} />
          </Layout>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  )
}
const mapStateToProps = state => {
  return { session: state.session }
}

export default connect(mapStateToProps)(App)
