import React from 'react';

import {
    useQuery,
    gql
} from "@apollo/client";


const SAY_HELLO = gql`
  query SayHello {
    sayHello {
      message
    }
  }
`;

export default function SayHello() {
    const { loading, error, data } = useQuery(SAY_HELLO);
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( </p>;
    return (
      <div>
        <p>
          {data.sayHello.message}
        </p>
      </div>
    );
  }

