import React, { Component } from 'react'
import logo from './logo.svg'
import './Home.css'
import { connect } from 'react-redux'
import request from 'request'
import appConfig from '../config/app-config.json'
import SayHello from '../components/queries/SayHello'

const mapStateToProps = state => {
  return { session: state.session }
}

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = { apiStatus: 'Not called' }
  }

  render () {
    return (
      <div>
        <header>
          <SayHello/>
        </header>
      </div>
    )
  }
}

export default connect(mapStateToProps)(Home)